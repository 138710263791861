import PrimaryPadding from "./common/components/PrimaryPadding";
import Header from "./common/components/Header";
import Stack from "./common/components/Stack";

const NotFound = () => {
  return (
    <PrimaryPadding>
      <Header />
      <Stack>
        <div className="text-center">
          <h2>Sheesh, page not found</h2>
          ...it's a little kinky
        </div>
      </Stack>
    </PrimaryPadding>
  );
};
export default NotFound;
