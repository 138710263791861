import { all } from "redux-saga/effects";
import * as VotingSagas from "../voting/VotingSagas";
import * as CodeInputSagas from "../joincreate/codeinput/CodeInputSagas";
import * as ModerateSagas from "../moderate/ModerateSagas";
import * as CreateCampaignSagas from "../joincreate/CampaignCreateSagas";

export default function* sagas() {
  // Count for the number of times I've forgot to add
  // the watcher: 8 number of times I've remembered: 2
  yield all([
    VotingSagas.watchFetch(),
    VotingSagas.watchUpvote(),
    VotingSagas.watchVotingCooldown(),
    VotingSagas.watchChangeCooldown(),
    VotingSagas.watchSetTimeoutPersistToLocalStorage(),
    VotingSagas.watchSubmit(),
    VotingSagas.watchSubmitSetTimeout(),
    VotingSagas.watchRecieveSubmittedChoiceId(),
    CodeInputSagas.watchCodeChange(),
    CodeInputSagas.watchCodeTyping(),
    ModerateSagas.watchStart(),
    ModerateSagas.watchDelete(),
    ModerateSagas.watchUpdateChoice(),
    ModerateSagas.watchCombineChoice(),
    CreateCampaignSagas.watchCreate(),
    CreateCampaignSagas.watchSuccessfullyCreated(),
  ]);
}
