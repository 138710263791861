import { Campaign, Choice } from "../CampaignProxy";
import { returnOrRemoveLimiter } from "./CooldownSubmitAgainPersister";

export const SUBMITTED_CHOICE_ID_TTK_MS = 6500;
export const DURATION_BETWEEN_VOTES_SECS = 8;
export const DURATION_BETWEEN_SUBMITS_MS = 20 * 1000;
export const SPAM_VOTING_COOLDOWN_MS = 3 * 1000;
export const ACTION_MEMORY_TTK_MS = 2500;

const UserNerfs = returnOrRemoveLimiter;

/**
 * This fucking reducer is unbarable because it focuses on commands
 * that are pretty much 'setters'. Need to focus on actions
 * action.nerfUserVotingCoolDownActive()
 * action.putUserInVotingCooldown()
 * action.putUserInSubmitCooldown()
 * action.temporarilyStoreSubmittedChoiceId()
 * action.clearStoreSubmittedChoiceId()
 */
export type ActionTypes =
  | "voting/fetch"
  | "voting/recieved"
  | "choices/updated"
  | "choices/upvote"
  | "voting/userIsSpamVoting"
  | "voting/userNoLongerSpamVoting"
  | "choices/upvoted/choiceId"
  | "choices/upvoted/remove"
  | "choices/upvoted/setTimeout"
  | "choices/upvoted/clearTimeout"
  | "choices/submitted/choiceId"
  | "choices/submitted/remove"
  | "choices/submit/setTimeout"
  | "choices/submit"
  | "voting/startCooldown"
  | "voting/changeCooldown"
  | "voting/block"
  | "voting/unblock";

interface Action<T> {
  type: ActionTypes;
  payload?: T;
}

export type RequestStatus = "PENDING" | "IN_PROGRESS" | "COMPLETE";
export type VoteAttemptStatus = "INITIAL" | "APPROVED" | "BLOCKED";

export interface State {
  requestStatus?: RequestStatus;
  campaign?: Campaign;
  submittedChoiceId: string;
  upvotedChoiceId: string;
  submitAgainAt: number;
  voteCooldownSecs: number;
  isSpamVoting: boolean;
  voteAttemptStatus: VoteAttemptStatus;
}

const initialState = {
  requestStatus: "PENDING",
  submittedChoiceId: "",
  upvotedChoiceId: "",
  submitAgainAt: Date.now(),
  voteCooldownSecs: 0,
  isSpamVoting: false,
  voteAttemptStatus: "INITIAL",
} as State;

export const reducer = (state = initialState, action: Action<any>) => {
  switch (action.type) {
    case "voting/changeCooldown":
      if (action.payload === undefined || action.payload <= 0) {
        return { ...state, voteCooldownSecs: 0 };
      }
      return { ...state, voteCooldownSecs: action.payload };
    case "voting/userIsSpamVoting":
      return { ...state, isSpamVoting: true };
    case "voting/userNoLongerSpamVoting":
      return { ...state, isSpamVoting: false };
    case "choices/upvoted/clearTimeout":
      return {
        ...state,
        upvoteAgainAt: 0,
      } as State;
    case "choices/submit/setTimeout":
      return {
        ...state,
        submitAgainAt: Date.now() + DURATION_BETWEEN_SUBMITS_MS,
      } as State;
    case "choices/upvoted/remove":
      return { ...state, upvotedChoiceId: "" } as State;
    case "choices/upvoted/choiceId":
      return { ...state, upvotedChoiceId: action.payload } as State;
    case "choices/submit":
      return {
        ...state,
      };
    case "choices/submitted/remove":
      return { ...state, submittedChoiceId: "" } as State;
    case "choices/submitted/choiceId":
      return {
        ...state,
        submittedChoiceId: action.payload,
      } as State;
    case "choices/upvote":
      const blockVote = state.voteAttemptStatus === "BLOCKED";
      if (blockVote) {
        return {
          ...state,
          voteAttemptStatus: "BLOCKED" as VoteAttemptStatus,
        };
      }

      const choice = action.payload.choice as Choice;
      const curCampaign = state.campaign as Campaign;
      const choices = Object.keys(curCampaign.choices).reduce(
        (acc, cur: string) => {
          if (cur === choice.id) {
            return {
              ...acc,
              [choice.id]: { ...choice, count: choice.count + 1 },
            };
          }

          return { ...acc, [cur]: curCampaign.choices[cur as any] };
        },
        {}
      );
      const campaignWithUpvoted = {
        ...state.campaign,
        choices,
      } as Campaign;

      return {
        ...state,
        campaign: campaignWithUpvoted,
        voteCooldownSecs: DURATION_BETWEEN_VOTES_SECS,
        upvotedChoiceId: choice.id,
        voteAttemptStatus: "APPROVED" as VoteAttemptStatus,
      };
    case "voting/block":
      return { ...state, voteAttemptStatus: "BLOCKED" as VoteAttemptStatus };
    case "voting/unblock":
      return { ...state, voteAttemptStatus: "INITIAL" as VoteAttemptStatus };

    case "choices/updated":
      return {
        ...state,
        campaign: { ...state.campaign, choices: action.payload },
      };
    case "voting/fetch":
      return { ...state, requestStatus: "IN_PROGRESS" };
    case "voting/recieved":
      const { campaign } = action.payload;

      return {
        ...state,
        requestStatus: "COMPLETE",
        campaign,
      };
    default:
      return state;
  }
};
const fetch = (campaignId: string): Action<{ campaignId: string }> => ({
  type: "voting/fetch",
  payload: { campaignId },
});

const recieved = (campaign: Campaign) => ({
  type: "voting/recieved",
  payload: {
    campaign,
  },
});

const choicesUpdated = (choices: Choice[]) => ({
  type: "choices/updated",
  payload: { choices },
});

const upvote = (campaignId: string, choice: Choice) => ({
  type: "choices/upvote",
  /// TODO: Campaign id should actually be retrived in the reducer
  payload: { choice, campaignId },
});

const submit = (text: string) => ({
  type: "choices/submit",
  payload: text,
});

const recieveSubmittedChoiceId = (choiceId: string) => ({
  type: "choices/submitted/choiceId" as ActionTypes,
  payload: choiceId,
});

const removeSubmittedChoiceId = () => ({
  type: "choices/submitted/remove" as ActionTypes,
});

const choiceVoted = (choiceId: string) => ({
  type: "choices/upvoted/choiceId" as ActionTypes,
  payload: choiceId,
});

const removeChoiceVoted = () => ({
  type: "choices/upvoted/remove" as ActionTypes,
});

const setSubmitTimeout = () => ({
  type: "choices/submit/setTimeout" as ActionTypes,
});

const setUpvoteTimeout = () => ({
  type: "choices/upvoted/setTimeout" as ActionTypes,
});

const clearTimeout = () => ({
  type: "choices/upvoted/clearTimeout" as ActionTypes,
});

/**
 * Voting cooldown
 */

const userIsSpamVoting = () => ({
  type: "voting/userIsSpamVoting" as ActionTypes,
});

const userNoLongerSpamVoting = () => ({
  type: "voting/userNoLongerSpamVoting" as ActionTypes,
});

const changeCooldown = (seconds: number) => ({
  type: "voting/changeCooldown" as ActionTypes,
  payload: seconds,
});

const startCooldown = () => ({
  type: "voting/startCooldown" as ActionTypes,
});

const blockVoting = () => ({
  type: "voting/block" as ActionTypes,
});
const unblockVoting = () => ({
  type: "voting/unblock" as ActionTypes,
});

export const actions = {
  fetch,
  recieved,
  choicesUpdated,
  upvote,
  submit,
  recieveSubmittedChoiceId,
  removeSubmittedChoiceId,
  choiceVoted,
  removeChoiceVoted,
  setSubmitTimeout,
  setUpvoteTimeout,
  clearTimeout,
  userIsSpamVoting,
  userNoLongerSpamVoting,
  changeCooldown,
  startCooldown,
  blockVoting,
  unblockVoting,
};
