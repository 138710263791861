import { takeEvery, select, put, call } from "redux-saga/effects";
import { actions, State } from "./CodeInputReducer";
import * as CampaignProxy from "../../CampaignProxy";

const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

export function* codeChange() {
  yield put(actions.typing());
  const codeStatus: State = yield select((state) => state.codeInput);
  if (codeStatus.inputStatus === "VALID_LENGTH") {
    try {
      type Rep = { found: boolean; campaignId: string };
      const res: Rep = yield call(CampaignProxy.isValidCode, codeStatus.code);

      yield put(actions.searchResult(res.campaignId));
    } catch {
      yield put(actions.searchResult());
    }
  }
}

export function* typing() {
  yield delay(500);
  yield put(actions.notTyping());
}

export function* watchCodeTyping() {
  yield takeEvery("codeinput/typing", typing);
}

export function* watchCodeChange() {
  yield takeEvery("codeinput/codechange", codeChange);
}
