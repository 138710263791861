import { lazy, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./joincreate/Home";
import { ThemeProvider, createTheme } from "@mui/material";
import { VotingProvider } from "./voting/VotingContext";
import Loading from "./Loading";
import NotFound from "./NotFound";

const theme = createTheme({
  typography: {
    fontFamily: "Graphik, sans-serif",
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          color: "seagreen",
          backgroundColor: "rgb(30, 41, 59)",
          border: "10px",
        },
      },
    },
  },
});

const CampaignCreate = lazy(
  () => import("./joincreate/CampaignCreate" /* webpackChunkName: "Create" */)
);
const Voting = lazy(
  () => import("./voting/Voting" /* webpackChunkName: "Voting" */)
);

const Moderate = lazy(
  () => import("./moderate/Moderate" /* webpackChunkName: "Moderate" */)
);

function App() {
  return (
    <>
      <BrowserRouter>
        <Suspense fallback={<Loading />}>
          <Routes>
            <Route path="/loading" element={<Loading />} />

            <Route
              path="/campaign/create/*"
              element={
                <ThemeProvider theme={theme}>
                  <CampaignCreate />
                </ThemeProvider>
              }
            />
            <Route
              path="/voting/:campaignId"
              element={
                <ThemeProvider theme={theme}>
                  <VotingProvider>
                    <Voting />
                  </VotingProvider>
                </ThemeProvider>
              }
            />
            <Route
              path="/voting/:campaignId/allan"
              element={
                <VotingProvider>
                  <Moderate />
                </VotingProvider>
              }
            />

            <Route path="/voting/" element={<Home />} />
            <Route path="/" element={<Home />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </>
  );
}

export default App;
