export const InitializedIcon = () => (
  <svg
    className="pointer-events-none w-8 h-8 absolute top-1/2 transform -translate-y-1/2 left-3 "
    width="32mm"
    height="32mm"
    version="1.1"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <text
        transform="matrix(.26458 0 0 .26458 -1.106 -4.1098)"
        fill="#000000"
      />
      <path
        transform="scale(.26458)"
        d="m98.752 0.18555-38.361 101.73-22.754-60.471c-1.6085 1.1171-3.3572 2.1995-5.2715 3.2324-2.8624 1.5446-5.6859 3.1655-8.5449 4.7168l27.344 71.621h18.533l46.049-120.83z"
        fill="#ffd644"
      />
    </g>
  </svg>
);

export const NotFoundIcon = () => {
  return (
    <svg
      onClick={() => {
        /**
         *  TODO: This Not found Icon is begging to be pressed.
         *   - onClick doesn't work.
         *
         *  Could we maybe add an overlay on the left of the text field
         *    - catch onClick, dispatch changeCode("") to clear field
         *  */
        console.log("mgdev svg cliki");
      }}
      className="animate-pulse text-red pointer-events-none w-8 h-8 absolute top-1/2 transform -translate-y-1/2 left-3 "
      focusable="false"
      aria-hidden="true"
      viewBox="0 0 24 24"
      data-testid="CancelIcon"
    >
      <path
        fill="currentColor"
        d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"
      ></path>
    </svg>
  );
};

export const FoundIcon = () => (
  <svg
    className="animate-pulse text-green pointer-events-none w-8 h-8 absolute top-1/2 transform -translate-y-1/2 left-3"
    width="32mm"
    height="32mm"
    version="1.1"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <text
        transform="matrix(.26458 0 0 .26458 -1.106 -4.1098)"
        fill="#000000"
      />
      <path
        transform="scale(.26458)"
        d="m98.752 0.18555-38.361 101.73-22.754-60.471c-1.6085 1.1171-3.3572 2.1995-5.2715 3.2324-2.8624 1.5446-5.6859 3.1655-8.5449 4.7168l27.344 71.621h18.533l46.049-120.83z"
        fill="#3f6212"
      />
    </g>
  </svg>
);

export const CheckingIcon = () => (
  <svg
    className="pointer-events-none w-8 h-8 absolute top-1/4 text-yellow left-3 animate-spin "
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <circle className="opacity-25" cx="12" cy="12" r="10"></circle>
    <path
      className="opacity-75"
      fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    ></path>
  </svg>
);
