const OrDivder = () => (
  <div className="text-secondary mt-4 mb-4 border border-0 flex flex-row space-evenly justfiy-center gap-4 items-center">
    <div className="grow">
      <hr />
    </div>
    <div className="grow-0">OR</div>
    <div className="grow">
      <hr />
    </div>
  </div>
);

export default OrDivder;
