import { post, get, put, remove } from "./common/proxies/http";

export interface Validation {
  id?: string;
  issues: Issue[];
}

export interface Issue {
  field: string;
  message: string;
}

export interface Choice {
  id?: string;
  choice: string;
  count: 0;
}

export interface Campaign {
  id?: string;
  name: string;
  choices: Choice[];
  code?: string;
}

export interface CampaignCreate {
  name: string;
  choices: string;
}

export type SubmitReponse =
  | { type: "non-error"; status: number; body: string }
  | { type: "error"; status: number; body: string };

export type CreateResponse =
  | { type: "non-error"; status: number; body: Campaign }
  | { type: "error"; status: number; body: Validation };

export type ValidCodeResponse =
  | { type: "error"; found: boolean }
  | {
      type: "non-error";
      campaignId: string;
      found: boolean;
    };

export const createv1 = (campaign: Campaign): Promise<CreateResponse> =>
  post("/campaign/create", campaign).then((res) => {
    if (res.status === 422) {
      return {
        type: "error",
        body: { ...res.body } as Validation,
        status: res.status,
      };
    }

    return {
      type: "non-error",
      body: { ...res.body } as Campaign,
      status: res.status,
    };
  });

export const createV2 = (campaign: CampaignCreate): Promise<CreateResponse> =>
  post("/campaign/create", campaign)
    .then((res) => {
      if (res.status === 422) {
        console.log("I'm in the tehe");
        return {
          type: "error",
          body: { ...res.body } as Validation,
          status: res.status,
        };
      }

      return {
        type: "non-error",
        body: { ...res.body } as Campaign,
        status: res.status,
      };
    })
    .catch((e) => {
      console.log(typeof e);
      return e;
    });
// .catch((e: unknown) => {
//   console.log({ ...e });

//   return ({
//     body: { ...e.response.data } as Validation,
//     status: res.status,
//   });
// });
// .catch((e: unknown) => {
//   return {
//     type: "error",
//     body: {} as Validation,
//     status: "nonError",
//   };
// });
//   console.log("I'm in the createv2", e);
//   return {
//     type: "non-error",
//     body: { ...res.body } as Campaign,
//     status: res.status,
//   };
// });

export const isValidCode = (code: string): Promise<ValidCodeResponse> =>
  post("campaign/codevalidation", { code }).then((res) => {
    if (res.status === 422) {
      return {
        type: "error",
        found: false,
      };
    }

    type Rep = { found: boolean; campaignId: string };
    const reps = { ...res.body } as Rep;

    return {
      type: "non-error",
      campaignId: reps.campaignId,
      found: reps.found,
    };
  });

export const fetch = (campaignId: string): Promise<CreateResponse> =>
  get(`/campaign/${campaignId}`).then((res) => ({
    type: "non-error",
    body: { ...res.body } as Campaign,
    status: res.status,
  }));

export const upvote = (
  campaignId: string,
  choiceId: string
): Promise<CreateResponse> =>
  put(`/campaign/${campaignId}/choice/${choiceId}/upvote`).then((res) => ({
    type: "non-error",
    body: { ...res.body } as Campaign,
    status: res.status,
  }));

export const submit = (
  campaignId: string,
  text: string
): Promise<SubmitReponse> =>
  post(`/campaign/${campaignId}/choice/`, { text }).then((res) => {
    if (res.status === 422) {
      return {
        type: "error",
        body: `${res.body}`, // Update CreateResponse to take a type T
        status: res.status,
      };
    }

    return {
      type: "non-error",
      body: `${res.body}`, // Update CreateResponse to take a type T
      status: res.status,
    };
  });

export const deleteChoice = (
  campaignId: string,
  choiceId: string
): Promise<SubmitReponse> =>
  remove(`/campaign/${campaignId}/choice/${choiceId}`).then((res) => ({
    type: "non-error",
    body: `${res.body}`, // Update CreateResponse to take a type T
    status: res.status,
  }));

export const updateChoice = (
  campaignId: string,
  choiceId: string,
  choice: any
): Promise<SubmitReponse> =>
  put(`/campaign/${campaignId}/choice/${choiceId}`, { ...choice }).then(
    (res) => ({
      type: "non-error",
      body: `${res.body}`, // Update CreateResponse to take a type T
      status: res.status,
    })
  );

export const combineChoice = (
  campaignId: string,
  keepChoiceId: string,
  tossChoiceId: string
): Promise<SubmitReponse> =>
  post(
    `/campaign/${campaignId}/choice/combine/keep/${keepChoiceId}/toss/${tossChoiceId}`,
    {}
  ).then((res) => ({
    type: "non-error",
    body: `${res.body}`, // Update CreateResponse to take a type T
    status: res.status,
  }));
