const VALID_CODE_LENGTH = 6;
export type ActionType =
  | "codeinput/reset"
  | "codeinput/codechange"
  | "codeinput/typing"
  | "codeinput/notTyping"
  | "codeinput/search"
  | "codeinput/search/recieved";

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export type InputStatus =
  | "EMPTY"
  | "TYPING"
  | "VALID_LENGTH"
  | "INVALID_LENGTH_GT"
  | "INVALID_LENGTH_LT";

export type CodeStatus = "UNKNOWN" | "CHECKING" | "FOUND" | "NOT_FOUND";

export interface State {
  code: string;
  inputStatus: InputStatus;
  codeStatus: CodeStatus;
  isUserTyping: boolean;
  campaignId?: undefined;
}

const initialState = {
  code: "",
  codeStatus: "UNKNOWN",
  inputStatus: "INVALID_LENGTH_LT",
  isUserTyping: false,
  campaignId: undefined,
} as State;

const getInputStatus = (code: string): InputStatus => {
  if (code.length === 0) return "EMPTY" as InputStatus;
  if (code.length === VALID_CODE_LENGTH) return "VALID_LENGTH" as InputStatus;
  if (code.length > VALID_CODE_LENGTH)
    return "INVALID_LENGTH_GT" as InputStatus;
  return "INVALID_LENGTH_LT" as InputStatus;
};

export const reducer = (state = initialState, action: Action<any>) => {
  switch (action.type) {
    case "codeinput/reset":
      return { initialState };
    case "codeinput/notTyping":
      return { ...state, isUserTyping: false };
    case "codeinput/typing":
      return { ...state, isUserTyping: true };
    case "codeinput/codechange":
      const inputStatus = getInputStatus(action.payload);
      return { ...state, inputStatus, code: action.payload };
    case "codeinput/search/recieved":
      const campaignId = action.payload;
      const found = String(campaignId).length > 0;
      const codeStatus = found ? "FOUND" : ("NOT_FOUND" as CodeStatus);
      return { ...state, campaignId, found, codeStatus };
    default:
      return state;
  }
};

const changeCode = (code: string): Action<string> => ({
  type: "codeinput/codechange",
  payload: `${code}`,
});

const searchResult = (campaignId: string = ""): Action<string> => ({
  type: "codeinput/search/recieved",
  payload: campaignId,
});

const typing = (): Action<undefined> => ({
  type: "codeinput/typing",
  payload: undefined,
});

const notTyping = (): Action<undefined> => ({
  type: "codeinput/notTyping",
  payload: undefined,
});

const reset = (): Action<undefined> => ({
  type: "codeinput/reset",
  payload: undefined,
});

export const actions = {
  changeCode,
  searchResult,
  typing,
  notTyping,
  reset,
};
