import { takeEvery, delay, put, call } from "redux-saga/effects";
import { ActionTypes, actions, Issues } from "./CreateReducer";
import { ACTION_MEMORY_TTK_MS } from "../voting/VotingReducer";

import * as CampaignProxy from "../CampaignProxy";

const toUsableIssues = (validation: CampaignProxy.Validation): Issues =>
  validation.issues.reduce((acc, cur) => {
    const { field, message } = cur;
    return { ...acc, [field]: message };
  }, {});

function* create(action: {
  type: string;
  payload: { name: string; choices: string };
}) {
  let res: CampaignProxy.CreateResponse | undefined = undefined;
  res = yield call(CampaignProxy.createV2, action.payload);

  if (res && res.type === "non-error") {
    const campaign = res.body as CampaignProxy.Campaign;
    if (campaign.id) {
      yield put(actions.successfullyCreated(campaign.id));
    }
  } else {
    if (res) {
      yield put(actions.failedToCreate(toUsableIssues(res.body)));
    }
  }
}

function* reinitialize() {
  console.log("reinitializing create");
  yield delay(ACTION_MEMORY_TTK_MS);
  yield put(actions.init());
}

export function* watchSuccessfullyCreated() {
  yield takeEvery(
    "campaign-create/createdSuccessfully" as ActionTypes,
    reinitialize
  );
}

export function* watchCreate() {
  yield takeEvery("campaign-create/attemptCreate" as ActionTypes, create);
}
