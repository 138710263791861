export type Status = "PENDING" | "IN_PROGRESS" | "FAILED" | "SUCCESS";
export type ActionTypes =
  | "campaign-create/init"
  | "campaign-create/attemptCreate"
  | "campaign-create/createdSuccessfully"
  | "campaign-create/failedToCreate";

interface Action<T> {
  type: ActionTypes;
  payload?: T;
}

export interface Issue {
  message: string;
}

export interface Issues {
  name?: Issue;
  choices?: Issue;
}

export interface State {
  status: Status;
  campaignId: "";
  issues: Issues;
}

const initialState: State = {
  status: "PENDING",
  campaignId: "",
  issues: {},
};

export const reducer = (state = initialState, action: Action<any>): State => {
  switch (action.type) {
    case "campaign-create/init" as ActionTypes:
      return { ...initialState };

    case "campaign-create/attemptCreate" as ActionTypes:
      return { ...state, status: "IN_PROGRESS" };

    case "campaign-create/failedToCreate" as ActionTypes:
      return { ...state, status: "FAILED", issues: action.payload };

    case "campaign-create/createdSuccessfully" as ActionTypes:
      return {
        ...state,
        status: "SUCCESS",
        campaignId: action.payload.campaignId,
      };

    default:
      return state;
  }
};

const attemptCreate = (name: string, choices: string) => ({
  type: "campaign-create/attemptCreate" as ActionTypes,
  payload: { name, choices },
});

const failedToCreate = (issues: Issues) => ({
  type: "campaign-create/failedToCreate" as ActionTypes,
  payload: issues,
});

const init = () => ({
  type: "campaign-create/init" as ActionTypes,
});

const successfullyCreated = (campaignId: string) => ({
  type: "campaign-create/createdSuccessfully" as ActionTypes,
  payload: { campaignId },
});

export const actions = {
  attemptCreate,
  failedToCreate,
  init,
  successfullyCreated,
};
