import React, { createContext, useReducer, Dispatch } from "react";

interface VotingUIContext {
  isCodeVisible: boolean;
  showVotingCooldownMsg: boolean;
}

const initialState: VotingUIContext = {
  isCodeVisible: false,
  showVotingCooldownMsg: false,
};

type ActionTypes = "voting/ui/toggleCodeVisibility";

interface Action<T> {
  type: ActionTypes;
  payload?: T;
}

const VotingContext = createContext<{
  state: VotingUIContext;
  dispatch: Dispatch<Action<any>>;
}>({ state: initialState, dispatch: () => null });

const useVotingContext = () => {
  const context = React.useContext(VotingContext);
  if (!context) {
    throw new Error("useVotingContext must be used within a VotingProvider");
  }

  return context;
};
const votingReducer = (state: VotingUIContext, action: Action<any>) => {
  switch (action.type) {
    case "voting/ui/toggleCodeVisibility":
      return {
        ...state,
        isCodeVisible: !state.isCodeVisible,
      } as VotingUIContext;
    default:
      return state;
  }
};

const VotingProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(votingReducer, initialState);
  return (
    <VotingContext.Provider value={{ state, dispatch }}>
      {children}
    </VotingContext.Provider>
  );
};

export { VotingContext, VotingProvider, useVotingContext };
