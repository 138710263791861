import { createRef, RefObject, useEffect } from "react";
import Header from "../common/components/Header";
import PrimaryPadding from "../common/components/PrimaryPadding";
import Stack from "../common/components/Stack";
import OrDivder from "../common/components/OrDivder";
import { useNavigate } from "react-router";
import CodeInput from "./codeinput/CodeInput";

import(/* webpackPrefetch: true */ "./CampaignCreate");

const Home = () => {
  useEffect(() => {
    // document.title = "🇴 🇹 🇷 | Create or Join";
    document.title = "🇻 🇴 🇹 🇷 | Create or Join";
  });

  const navigate = useNavigate();
  const nameRef: RefObject<HTMLInputElement> = createRef();
  return (
    <div className="antialiased">
      <PrimaryPadding>
        <Header />
        <Stack>
          <CodeInput />
          <OrDivder />
          <input
            ref={nameRef}
            className=""
            placeholder="What are we voting for?"
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                const params = new URLSearchParams();
                params.set("name", String(nameRef.current?.value));
                navigate(`/campaign/create/?${params.toString()}`);
              }
            }}
          />
          <button
            onClick={() => {
              const params = new URLSearchParams();
              params.set("name", String(nameRef.current?.value));
              navigate(`/campaign/create/?${params.toString()}`);
            }}
          >
            Begin
          </button>
        </Stack>
      </PrimaryPadding>
    </div>
  );
};

export default Home;
