import { useNavigate } from "react-router";
import Stack from "./Stack";

const MobileHeader = () => {
  const navigate = useNavigate();
  return (
    <Stack>
      <div
        className="text-center m-15 cursor-pointer"
        onClick={() => {
          navigate("/");
        }}
      >
        <h1 className="drop-shadow-2xl voterShadow">Votr</h1>
        <em className="tracking-wider">...where revolution begins</em>
      </div>
    </Stack>
  );
};

export default MobileHeader;
