import {
  take,
  takeEvery,
  takeLatest,
  put,
  call,
  select,
  fork,
  delay,
  cancel,
  spawn,
  cancelled,
} from "redux-saga/effects";
import {
  ActionTypes,
  actions,
  ACTION_MEMORY_TTK_MS,
  SPAM_VOTING_COOLDOWN_MS,
  State,
  VoteAttemptStatus,
} from "./VotingReducer";
import * as CampaignProxy from "../CampaignProxy";
import { eventChannel, END, EventChannel, Task } from "@redux-saga/core";
import {
  persistCooldownLocalStorage,
  persistSubmitAgainLocalStorage,
  returnOrRemoveLimiter,
} from "./CooldownSubmitAgainPersister";

function* fetchCampaign(action: { type: ""; payload: { campaignId: string } }) {
  const response: CampaignProxy.CreateResponse = yield call(
    CampaignProxy.fetch,
    action.payload.campaignId
  );
  if (response.type === "non-error") {
    yield put(actions.recieved(response.body));

    // User might have refreshed thinking they could bypass UI restrictions
    const limiter = returnOrRemoveLimiter(action.payload.campaignId);
    if (limiter) {
      yield put(actions.changeCooldown(limiter.voteCooldownSecs));
      if (limiter.voteCooldownSecs > 0) {
        yield put(actions.startCooldown());
        yield put(actions.blockVoting());
      }

      if (limiter.submitAgainAt > Date.now()) {
        yield put(actions.setSubmitTimeout());
      }
    }
  }
}

// // TODO: remove the `any` on `payload`
function* submit(action: { type: ""; payload: string }) {
  const text = action.payload;
  const campaignId: string = yield select((state) => state.voting.campaign.id);

  const response: CampaignProxy.SubmitReponse = yield call(
    CampaignProxy.submit,
    campaignId,
    text
  );

  console.log("VotingSaga submit response", response);

  if (response.type === "non-error") {
    const choiceId = response.body;
    yield put(actions.recieveSubmittedChoiceId(choiceId));
    // const fetchResponse: CampaignProxy.CreateResponse = yield call(
    //   CampaignProxy.fetch,
    //   campaignId
    // );

    // yield put(actions.recieved(fetchResponse.body as CampaignProxy.Campaign));
    // yield put(actions.recieved(fetchResponse.body as CampaignProxy.Campaign));
  }
}

function* removeSubmittedChoiceId() {
  yield fork(removeSubmittedChoiceIdImpl);
}

function* setSubmitTimeOut() {
  yield put(actions.setSubmitTimeout());
}

function* removeSubmittedChoiceIdImpl() {
  yield delay(ACTION_MEMORY_TTK_MS);
  yield put(actions.removeSubmittedChoiceId());
}

export function* watchFetch() {
  yield takeEvery("voting/fetch" as ActionTypes, fetchCampaign);
}

export function* watchRecieveSubmittedChoiceId() {
  yield takeEvery(
    "choices/submitted/choiceId" as ActionTypes,
    removeSubmittedChoiceId
  );
}

function* persistCooldown() {
  const voting: State = yield select((state) => state.voting);
  persistCooldownLocalStorage(voting);
}

export function* watchChangeCooldown() {
  yield takeEvery("voting/changeCooldown" as ActionTypes, persistCooldown);
}

export function* watchSubmit() {
  yield takeEvery("choices/submit" as ActionTypes, submit);
}

/**
 * Todo this is unncessary move the fork(action) back into the `submit` generator
 */
export function* watchSubmitSetTimeout() {
  yield takeEvery("choices/submit" as ActionTypes, setSubmitTimeOut);
}

function* persistSubmitTimeout() {
  const voting: State = yield select((state) => state.voting);
  persistSubmitAgainLocalStorage(voting);
}

export function* watchSetTimeoutPersistToLocalStorage() {
  yield takeEvery(
    "choices/submit/setTimeout" as ActionTypes,
    persistSubmitTimeout
  );
}

/**
 * Voting sagas
 *  */

function votingCountdown(secs: number): EventChannel<number> {
  return eventChannel((emitter) => {
    const iv = setInterval(() => {
      secs -= 1;
      if (secs > 0) {
        emitter(secs);
      } else {
        emitter(END);
      }
    }, 1000);
    return () => {
      clearInterval(iv);
    };
  });
}

function* votingCountdownTask(secs: number): any {
  const channel: EventChannel<number> = yield call(votingCountdown, secs);

  try {
    while (true) {
      const sec: number = yield take(channel);
      yield put(actions.changeCooldown(sec));
    }
  } finally {
    if (yield cancelled()) {
      channel.close();
    } else {
      yield put(actions.changeCooldown(0));
      yield put(actions.unblockVoting());
    }
  }
}

function* clearRecentActionMemory() {
  yield delay(ACTION_MEMORY_TTK_MS);
  yield put(actions.removeChoiceVoted());
  yield put(actions.userNoLongerSpamVoting());
}

function* upvote(action: {
  type: "";
  payload: { campaignId: string; choice: { id: string } };
}) {
  // Prevent user from spam voting
  const voteAttemptStatus: VoteAttemptStatus = yield select(
    (state) => state.voting.voteAttemptStatus
  );

  if (voteAttemptStatus === "APPROVED") {
    yield put(actions.blockVoting());
    yield put(actions.startCooldown());
    yield call(
      CampaignProxy.upvote,
      action.payload.campaignId,
      action.payload.choice.id
    );
  } else if (voteAttemptStatus === "BLOCKED") {
    yield put(actions.userIsSpamVoting());
  }
  // Should we be starting the cooldown again?
  yield put(actions.startCooldown());
  yield spawn(clearRecentActionMemory);
}

function* userNoLongerSpamVoting() {
  yield delay(SPAM_VOTING_COOLDOWN_MS);
  yield put(actions.userNoLongerSpamVoting());
}

export function* watchIsUserSpamVoting() {
  yield takeLatest(
    "voting/userIsSpamVoting" as ActionTypes,
    userNoLongerSpamVoting
  );
}
export function* watchVotingCooldown() {
  while (true) {
    yield take("voting/startCooldown" as ActionTypes);

    const voteCooldownSecs: number = yield select(
      (state) => state.voting.voteCooldownSecs
    );

    const task: Task = yield fork(votingCountdownTask, voteCooldownSecs);

    const cooldownVoting: State = yield take(
      "voting/changeCooldown" as ActionTypes
    );
    if (cooldownVoting.voteCooldownSecs === 0) {
      yield cancel(task);
    }
  }
}

export function* watchUpvote() {
  yield takeLatest("choices/upvote" as ActionTypes, upvote);
}
