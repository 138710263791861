import { createStore, applyMiddleware, combineReducers, compose } from "redux";
import createSagaMiddleware from "@redux-saga/core";

import rootSaga from "./RootSaga";
import { reducer as VotingReducer } from "../voting/VotingReducer";
import { reducer as CodeInputReducer } from "../joincreate/codeinput/CodeInputReducer";
import { reducer as ModerateReducer } from "../moderate/ModerateReducer";
import { reducer as CreateReducer } from "../joincreate/CreateReducer";

/* eslint-disable */
const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const sagaMiddleware = createSagaMiddleware();
const store = createStore(
  combineReducers({
    voting: VotingReducer,
    codeInput: CodeInputReducer,
    moderate: ModerateReducer,
    createCampaign: CreateReducer,
  }),
  composeEnhancers(applyMiddleware(sagaMiddleware))
);

sagaMiddleware.run(rootSaga);

export default store;
