import { Campaign } from "../CampaignProxy";
export type ActionTypes =
  | "moderate/start"
  | "moderate/recievedCampaign"
  | "moderate/end"
  | "moderate/selectChoice"
  | "moderate/selectDelete"
  | "moderate/selectCombine"
  | "moderate/updateChoice";

export type Operation = "VIEW" | "EDIT" | "COMBINE";

interface Action<T> {
  type: ActionTypes;
  payload?: T;
}

export type RequestStatus = "PENDING" | "IN_PROGRESS" | "COMPLETE";

export type DeleteStatus = "NONE" | "CONFIRM";
const getNextDeleteStatus = (deleteStatus: DeleteStatus): DeleteStatus => {
  if (deleteStatus === "CONFIRM") return "NONE";
  return "CONFIRM";
};

export type CombineStatus =
  | "NONE"
  | "INITIAL"
  | "CONFIRMING"
  | "IN_PROGRESS"
  | "CONFIRMED";
const getNextCombineStatus = (combineStatus: CombineStatus): CombineStatus => {
  if (combineStatus === "NONE") return "INITIAL";
  if (combineStatus === "INITIAL") return "CONFIRMING";
  if (combineStatus === "CONFIRMING") return "IN_PROGRESS";
  if (combineStatus === "IN_PROGRESS") return "CONFIRMED";
  return "NONE";
};

export interface State {
  requestStatus: RequestStatus;
  campaign?: Campaign;
  selectedChoice: string;
  deleteStatus: DeleteStatus;
  combineChoiceId: string;
  operation: Operation;
  combineStatus: CombineStatus;
}

const initialState = {
  requestStatus: "PENDING",
  selectedChoice: "",
  deleteStatus: "NONE",
  combineChoiceId: "",
  operation: "VIEW",
  combineStatus: "NONE",
} as State;

export const reducer = (state = initialState, action: Action<any>): State => {
  switch (action.type) {
    case "moderate/start":
      return { ...state, selectedChoice: "", requestStatus: "IN_PROGRESS" };
    case "moderate/recievedCampaign":
      return {
        ...state,
        campaign: action.payload as Campaign,
        requestStatus: "COMPLETE",
      };
    case "moderate/selectChoice":
      return {
        ...state,
        selectedChoice: action.payload,
        operation: "EDIT",
      };
    case "moderate/end":
      return {
        ...state,
        selectedChoice: "",
        deleteStatus: "NONE",
        combineStatus: "NONE",
        combineChoiceId: "",
        operation: "VIEW",
      };
    case "moderate/selectDelete":
      return {
        ...state,
        deleteStatus: getNextDeleteStatus(state.deleteStatus),
      };
    case "moderate/selectCombine":
      return {
        ...state,
        combineChoiceId: action.payload,
        combineStatus: getNextCombineStatus(state.combineStatus),
        operation: "COMBINE",
      };
    default:
      return { ...state };
  }
};

const start = (campaignId: string): Action<{ campaignId: string }> => ({
  type: "moderate/start" as ActionTypes,
  payload: { campaignId },
});

const recievedCampaign = (campaign: Campaign): Action<Campaign> => ({
  type: "moderate/recievedCampaign" as ActionTypes,
  payload: campaign,
});

const end = (): Action<undefined> => ({
  type: "moderate/end" as ActionTypes,
});
const selectChoice = (choiceId: string): Action<string> => ({
  type: "moderate/selectChoice" as ActionTypes,
  payload: choiceId,
});

const selectDelete = () => ({
  type: "moderate/selectDelete" as ActionTypes,
});

const confirmDelete = () => ({
  type: "moderate/confirmDelete" as ActionTypes,
});

const updateChoice = (count: number, text: string) => ({
  type: "moderate/updateChoice" as ActionTypes,
  payload: {
    count,
    text,
  },
});

const combineChoice = (choiceId: string) => ({
  type: "moderate/selectCombine" as ActionTypes,
  payload: choiceId,
});

const pendingUpdates = () => ({
  type: "moderate/pendingUpdates" as ActionTypes,
});

export const actions = {
  recievedCampaign,
  start,
  end,
  selectChoice,
  selectDelete,
  confirmDelete,
  updateChoice,
  pendingUpdates,
  combineChoice,
};
