import { useSelector, useStore } from "react-redux";
import { actions, State as CodeState } from "./CodeInputReducer";
import { useNavigate } from "react-router";
import {
  CheckingIcon,
  NotFoundIcon,
  FoundIcon,
  InitializedIcon,
} from "./Icons";

const CodeInputValidator = (): JSX.Element => {
  const codeState = useSelector(
    (state: { codeInput: CodeState }) => state.codeInput
  );

  if (codeState.isUserTyping) return <CheckingIcon />;
  if (codeState.inputStatus === "VALID_LENGTH") {
    if (codeState.codeStatus === "NOT_FOUND") return <NotFoundIcon />;
    if (codeState.codeStatus === "FOUND") return <FoundIcon />;
  } else {
    if (codeState.inputStatus === "INVALID_LENGTH_GT") return <NotFoundIcon />;
  }

  return <InitializedIcon />;
};

const CodeInput = () => {
  const store = useStore();
  const navigate = useNavigate();
  const codeState = useSelector(
    (state: { codeInput: CodeState }) => state.codeInput
  );

  if (codeState.codeStatus === "FOUND") {
    store.dispatch(actions.reset());
    navigate(`/voting/${codeState.campaignId}`);
  }

  return (
    <label className="relative text-gray-400 focus-within:text-gray-600 block w-full">
      <CodeInputValidator />
      <input
        type="tel"
        className="w-full text-center tracking-widerest "
        placeholder="Join code"
        onChange={(e) =>
          store.dispatch(actions.changeCode(e.currentTarget.value))
        }
      />
    </label>
  );
};

export default CodeInput;
