import { takeEvery, put, call, select } from "redux-saga/effects";
import { actions, ActionTypes, DeleteStatus, State } from "./ModerateReducer";
import * as CampaignProxy from "../CampaignProxy";

function* fetchCampaign(action: { type: ""; payload: { campaignId: string } }) {
  const response: CampaignProxy.CreateResponse = yield call(
    CampaignProxy.fetch,
    action.payload.campaignId
  );
  if (response.type === "non-error") {
    yield put(actions.recievedCampaign(response.body));
  }
}

function* deletE() {
  const deleteStatus: DeleteStatus = yield select(
    (state) => state.moderate.deleteStatus
  );
  if (deleteStatus === "NONE") {
    const state: State = yield select((state) => state.moderate);

    const response: CampaignProxy.SubmitReponse = yield call(
      CampaignProxy.deleteChoice,
      String(state.campaign?.id),
      state.selectedChoice
    );

    if ((response.type = "non-error")) {
      yield put(actions.end());
      yield put(actions.start(String(state.campaign?.id)));
    }
  }
}

function* updateChoice(action: {
  type: string;
  payload: { count: number; text: string };
}) {
  const campaignId: string = yield select(
    (state) => state.moderate.campaign.id
  );
  const choiceId: string = yield select(
    (state) => state.moderate.selectedChoice
  );

  const response: CampaignProxy.SubmitReponse = yield call(
    CampaignProxy.updateChoice,
    campaignId,
    choiceId,
    { text: action.payload.text, delta: Number(action.payload.count) }
  );

  if ((response.type = "non-error")) {
    yield put(actions.end());
    yield put(actions.start(String(campaignId)));
  }
}

function* selectCombine() {
  const moderate: State = yield select((state) => state.moderate);
  if (moderate.combineStatus === "IN_PROGRESS" && moderate.campaign?.id) {
    const state: State = yield select((state) => state.moderate);
    const response: CampaignProxy.SubmitReponse = yield call(
      CampaignProxy.combineChoice,
      moderate.campaign?.id,
      moderate.combineChoiceId,
      moderate.selectedChoice
    );

    if ((response.type = "non-error")) {
      yield put(actions.end());
      yield put(actions.start(String(state.campaign?.id)));
    }
  }
}

export function* watchStart() {
  yield takeEvery("moderate/start" as ActionTypes, fetchCampaign);
}

export function* watchDelete() {
  yield takeEvery("moderate/selectDelete" as ActionTypes, deletE);
}

export function* watchCombineChoice() {
  yield takeEvery("moderate/selectCombine" as ActionTypes, selectCombine);
}

export function* watchUpdateChoice() {
  yield takeEvery("moderate/updateChoice" as ActionTypes, updateChoice);
}
