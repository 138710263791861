import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import { BASE_URL } from "./baseProxy";

export interface Response {
  status: number;
  body: object;
}

const https = (): AxiosInstance =>
  axios.create({
    baseURL: BASE_URL(),
    headers: { "Content-Type": "application/json" },
  });

export const put = (url: string, body?: any): Promise<Response> => {
  return https()
    .put(url, body)
    .then((res: AxiosResponse) => {
      return {
        status: res.status,
        body: res.data,
      };
    });
};

export const get = (url: string): Promise<Response> => {
  return https()
    .get(url)
    .then((res: AxiosResponse) => {
      return {
        status: res.status,
        body: res.data,
      };
    });
};

export const post = (url: string, body: object): Promise<Response> => {
  return https()
    .post(url, body)
    .then((res: AxiosResponse) => {
      return {
        status: res.status,
        body: res.data,
      };
    })
    .catch((e: Error | AxiosError) => {
      console.log("here", JSON.stringify(e));
      // return e;
      if (axios.isAxiosError(e)) {
        if (e.response) {
          return {
            status: e.response.status,
            body: e.response.data,
          };
        }
      }

      return {
        status: 422,
        body: {},
      };
    });
};

export const remove = (url: string): Promise<Response> => {
  return https()
    .delete(url)
    .then((res: AxiosResponse) => {
      return {
        status: res.status,
        body: res.data,
      };
    });
};
