import { State } from "./VotingReducer";

const LOCAL_STORAGE_KEY = "limiter";

type Limiter = [String, UserNerfs];

/**
 * This was limiting implementation
 * we need to thing more of user actions
 * what's important is that they are in cooldown/timeout
 * not the remaining duration.
 */
interface UserNerfs {
  voteCooldownSecs: number;
  submitAgainAt: number;
}

const defaultUserNerfs = {
  voteCooldownSecs: 0,
  submitAgainAt: Date.now() - 10 * 60 * 1000,
};

export const returnOrRemoveLimiter = (
  currentCampaignId: string
): UserNerfs | undefined => {
  const localstoreLimiter: Limiter = JSON.parse(
    // @ts-ignore
    localStorage.getItem("limiter")
  );
  if (!localstoreLimiter) {
    return undefined;
  }

  const [campaignId, userNerfs] = localstoreLimiter;
  if (campaignId !== currentCampaignId) {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify([]));
    return undefined;
  }

  return userNerfs;
};

export const persistCooldownLocalStorage = (state: State) => {
  const { campaign, voteCooldownSecs } = state;
  if (!campaign) throw Error("We don't have a campaign");
  if (!campaign.id) throw Error("We don't have a campaign id");

  // @ts-ignore
  const currentNerfs = returnOrRemoveLimiter(campaign.id);

  localStorage.setItem(
    LOCAL_STORAGE_KEY,
    JSON.stringify([
      campaign.id,
      {
        ...defaultUserNerfs,
        ...(currentNerfs || {}),
        voteCooldownSecs,
      },
    ])
  );
};

export const persistSubmitAgainLocalStorage = (state: State) => {
  // get current localstorage at campagin id
  const { campaign, submitAgainAt } = state;
  if (!campaign) throw Error("We don't have a campaign");
  if (!campaign.id) throw Error("We don't have a campaign id");

  // @ts-ignore
  const currentNerfs = returnOrRemoveLimiter(campaign.id);

  localStorage.setItem(
    LOCAL_STORAGE_KEY,
    JSON.stringify([
      campaign.id,
      {
        ...defaultUserNerfs,
        ...(currentNerfs || {}),
        submitAgainAt,
      },
    ])
  );
};
